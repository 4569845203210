import React from "react"
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Ishango from './../components/Ishangodark'
import Signature from './../components/image'

import Background from './../images/ishango.svg'

class IndexPage extends React.Component{
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState({ show: !this.state.show });
  }
  render(){
    return (
      <div>
        <section id="homepage" className="bg-white">
          <div className="container-fluid">
            <div>
            <div className="row min-vh-100 py-lg-8 py-6 justify-content-center align-items-center pt-lg-0" data-aos="fade-up">
              <div className="col-12 col-lg-6 text-dark pt-lg-8">
                <div className="py-4 d-lg-none text-center mb-6">
                  <img src={Background} className="w-35" alt="logo" />
                </div>
                <h1 className="display-4 pb-lg-4 ">
                  We are Organic
                </h1>
                <span><span className="font-weight-bolder">Organic</span> [ɔːʳgænɪk]<br />adjective (OF CHANGES), <br />happening gradually and naturally rather than suddenly.</span>
                <br /><br />
                
                <p className="mb-0 text-justify" data-aos="fade-zoom-in">
                On September 6, 2012, a small branding company was founded in Kigali. The company’s Chief Executive Officer would, for the next few months, often find himself chuckling at his title, since he was essentially officiating over himself: He was the company's sole employee!
                  <br /><br />
                  But the company was founded upon the unwavering determination to grow into a robust provider of services to not only clients in Rwanda, but also beyond. Our company's philosophy is one of authenticity and originality. We are strong believers in custom-made solutions for our clients and it what we strive to deliver; hence, our name: Organic Inc. (no, we are not in the food industry).
                  
                  {
                    this.state.show? <div data-aos="fade-zoom-in" data-aos-duration="2000">
                      <br />
                      Eight years down the road, the journey has been nothing short of rewarding. Our determination, obsession with quality, swift delivery, and long-term vision have enabled us to grow in ways we could have never imagined.
                      <br /><br />
                      True to the Organic Inc. motto, we are always <span className="font-weight-bolder">stretching our boundaries</span>. We started off as a small firm providing branding services, and are today transitioning into a holding company with three subsidiaries, namely:
                      <br /><br />
                      <ul>
                        <li><span className="font-weight-bolder">Yatọ</span>: A Creative Communications and Corporate Events management company.<br /></li>
                        <li><span className="font-weight-bolder">Milliseconds</span>: A creative studio, with special focus on architecture, graphic, and motion design.<br /></li>
                        <li><span className="font-weight-bolder">Blanc</span>: An interior design & fittings company.<br /></li>
                      </ul>
                      With the new structure, we believe we will create an even more sustainable and ever-expanding array of services to our customers. If you are looking for fresh and durable solutions for your brand, Yatọ, Milliseconds, and Blanc are at your disposal.
                      <br /><br />
                      Thank you for visiting our website and we look forward to hearing from you.
                      <br /><br />
                      Yours Without Wax,
                      <br /><br />
                      <Signature />
                      <br />
                      CEO, Organic Inc.
                    </div> : null
                }
                  <a className="mt-n4 text-primary linethrough" onClick={this.handleClick}>{ this.state.show ? null : ' more' }</a>
                  
                </p>
              </div>
              <div className="col-12 col-lg-4 offset-lg-1 mt-8 " data-aos-duration="1000" data-aos="fade-in" data-aos-delay="1000">
                <div>
                  <div>
                    {
                      this.state.show? null : <div>
                        <img src={Background} className="w-75 d-none d-lg-block" alt="logo" />
                      </div>
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </section>
    </div>
    );
  }
}
export default IndexPage
